import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Typography,
  notification,
} from 'antd'
import axios from 'axios'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../config'

const { Title } = Typography
const { TextArea } = Input

const V2MiddlewareAdd = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  useEffect(() => {
    const robots = form.getFieldValue('robots')
    if (!robots || robots.length === 0) {
      form.setFieldsValue({
        robots: [{ id: '', modelCode: '', nickname: '' }],
      })
    }
  }, [form])

  const onFinish = async (values: any) => {
    try {
      const firstRobotModelCode = values.robots[0]?.modelCode
      let company = ''

      if (firstRobotModelCode === 'LGSR2') {
        company = 'LG'
      } else if (firstRobotModelCode === 'BRSR') {
        company = 'BRSR'
      }

      const requestData = {
        ...values,
        company,
      }

      await axios.post(`${API_URL}/middlewareEC2/middleware/add`, requestData)
      notification.success({
        message: 'Success',
        description: 'Middleware added successfully',
      })
      navigate(-1)
    } catch (error) {
      console.error('Error adding middleware:', error)
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          notification.error({
            message: 'Error',
            description: '비어있는 EC2 가 없습니다.',
          })
        }
      } else {
        notification.error({
          message: 'Error',
          description: '미들웨어 추가에 실패했습니다.',
        })
      }
    }
  }

  const handleConfigClick = (config: string) => {
    form.setFieldsValue({
      config: config,
    })
  }

  const lgConfig = JSON.stringify(
    {
      lg_config: {
        host: 'https://kic-svc.lgerobot.com/robot/b2b',
        'x-api-key': '',
        'x-user-no': '',
        'x-biz-channel': '',
        'x-country-code': 'KR',
        'x-language-code': 'ko-KR',
        'x-user-role': '',
        'refresh-token': '',
      },
    },
    null,
    2,
  )

  const puduConfig = JSON.stringify(
    {
      pudu_config: {
        host: 'https://kic-svc.lgerobot.com/robot/b2b',
        port: 'number',
        device_id: 'string',
        group_id: 'string',
      },
    },
    null,
    2,
  )

  const bearConfig = JSON.stringify(
    {},
    null,
    2,
  )

  const allConfig = JSON.stringify(
    {
      lg_config: {
        host: 'https://kic-svc.lgerobot.com/robot/b2b',
        'x-api-key': '29286223492d4ddd823849ab2489b8d2',
        'x-user-no': '000073KR2310110475873',
        'x-biz-channel': 'CHN000073',
        'x-country-code': 'KR',
        'x-language-code': 'ko-KR',
        'x-user-role': 'USR07305324',
        'refresh-token':
          '6778854031828f52d0e263eed8cd616ac768014dc89fbcc9e3b757ff52e13a29913aab18a09fd4b795a06150fa34306a',
      },
      pudu_config: {
        host: 'https://kic-svc.lgerobot.com/robot/b2b',
        port: 'number',
        device_id: 'string',
        group_id: 'string',
      },
      bear_config: {
        host: 'https://kic-svc.lgerobot.com/robot/b2b',
        vhost: 'string',
        auth_id: 'string',
        auth_key_file_path: 'string',
        mq_data_expiration_time: 'number',
      },
    },
    null,
    2,
  )

  return (
    <div>
      <Title level={2}>미들웨어 추가</Title>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          robots: [{ id: '', modelCode: '', nickname: '' }],
        }}
      >
        <Form.Item
          name="storeId"
          label="매장 ID"
          rules={[{ required: true, message: 'Please input the store ID' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="config"
          label="Config"
          rules={[{ required: true, message: 'Please input the config' }]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          {/* <Button onClick={() => handleConfigClick(allConfig)}>ALL</Button> */}
          <Button onClick={() => handleConfigClick(lgConfig)}>LG</Button>
          {/* <Button onClick={() => handleConfigClick(puduConfig)}>PUDU</Button> */}
          <Button onClick={() => handleConfigClick(bearConfig)}>BEAR</Button>
        </Form.Item>
        <Form.Item label="로봇 목록" required>
          <Form.List name="robots">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'id']}
                      rules={[{ required: true, message: 'Missing robot ID' }]}
                    >
                      <Input placeholder="Robot ID" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'modelCode']}
                      rules={[
                        { required: true, message: 'Missing model code' },
                      ]}
                      style={{ flexGrow: 1, minWidth: '200px'}}
                    >
                      <Select placeholder="">
                        <Select.Option value="LGSR2">
                          LG CLOi: 2세대/3세대
                        </Select.Option>
                        <Select.Option value="BRSR">
                          Bear Robotics
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'nickname']}
                      rules={[{ required: true, message: 'Missing nickname' }]}
                    >
                      <Input placeholder="Nickname" />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => {
                        const robots = form.getFieldValue('robots')
                        if (robots.length > 1) {
                          remove(name)
                        }
                      }}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Robot
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button
            type="default"
            onClick={() => navigate(-1)}
            style={{ marginLeft: '8px' }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default V2MiddlewareAdd
