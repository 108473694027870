import { Button, Layout, Table, theme } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../config'

const { Content } = Layout

export const loader = async () => {
  try {
    const response = await axios.post(`${API_URL}/store/list`, {})
    return response.data.result
  } catch (error) {
    console.error('Error fetching store data:', error)
    throw new Error('Failed to load data')
  }
}

const Store = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${API_URL}/store/list`, {})
        setData(response.data.result)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const columns = [
    {
      title: 'No.',
      dataIndex: 'seq',
      key: 'seq',
      // render: (text: string, record: any) => (
      //   <Link to={`/ec2s/${record.ec2Id}/edit`} style={{ color: 'blue' }}>
      //     {text}
      //   </Link>
      // ),
    },
    {
      title: '매장 ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '매장명',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '생성일시',
      dataIndex: 'reg',
      key: 'reg',
    },
    {
      title: '수정일시',
      dataIndex: 'edit',
      key: 'edit',
    },
  ]

  return (
    <div>
      <Button
        type="primary"
        onClick={() => navigate('/stores/add')}
        style={{ marginBottom: 16 }}
      >
        매장 추가
      </Button>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="id"
      />
    </div>
  )
}

export default Store
