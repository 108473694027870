import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import {
  Button,
  Form,
  Input,
  ModalFuncProps,
  Space,
  Typography,
  notification,
} from 'antd'
import confirm from 'antd/es/modal/confirm'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { API_URL } from '../config'

const { Title } = Typography
const { TextArea } = Input

const MiddlewareEdit = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [initialValues, setInitialValues] = useState({})
  const [confirmLoading, setConfirmLoading] = useState(false)

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this middleware?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okCancel: true,
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setConfirmLoading(true)
        handleDelete()
      },
    } as ModalFuncProps)
  }

  const handleDelete = async () => {
    setConfirmLoading(true)
    try {
      await axios.post(
        `${API_URL}/middlewareEC2/middleware/del`,
        {
          id: id,
        },
      )
      notification.success({
        message: 'Success',
        description: 'Middleware deleted successfully',
      })
      navigate(-1)
    } catch (error) {
      console.error('Error deleting middleware:', error)
      notification.error({
        message: 'Error',
        description: `미들웨어 삭제에 실패했습니다`,
      })
    } finally {
      setConfirmLoading(false)
    }
  }

  useEffect(() => {
    const fetchMiddleware = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/middlewareEC2/middleware/${id}`,
        )
        setInitialValues(response.data)
        form.setFieldsValue(response.data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching middleware data:', error)
        setLoading(false)
      }
    }

    fetchMiddleware()
  }, [id, form])

  const onFinish = async (values: any) => {
    try {
      await axios.post(
        `${API_URL}/middlewareEC2/middleware/config/edit`,
        values,
      )
      notification.success({
        message: 'Success',
        description: 'Middleware updated successfully',
      })
      navigate('/middlewares')
    } catch (error) {
      console.error('Error updating middleware:', error)
      notification.error({
        message: 'Error',
        description: `미들웨어 업데이트에 실패했습니다`,
      })
    }
  }

  const handleConfigClick = (config: string) => {
    form.setFieldsValue({
      config: config,
    })
  }

  const lgConfig = JSON.stringify(
    {
      lg_config: {
        host: 'https://kic-svc.lgerobot.com/robot/b2b',
        'x-api-key': '',
        'x-user-no': '',
        'x-biz-channel': '',
        'x-country-code': 'KR',
        'x-language-code': 'ko-KR',
        'x-user-role': '',
        'refresh-token':
          '',
      },
    },
    null,
    2,
  )

  const puduConfig = JSON.stringify(
    {
      pudu_config: {
        host: 'string',
        port: 'number',
        device_id: 'string',
        group_id: 'string',
      },
    },
    null,
    2,
  )

  const bearConfig = JSON.stringify(
    {
      bear_config: {
        host: 'string',
        vhost: 'string',
        auth_id: 'string',
        auth_key_file_path: 'string',
        mq_data_expiration_time: 'number',
      },
    },
    null,
    2,
  )

  const allConfig = JSON.stringify(
    {
      lg_config: {
        host: 'https://kic-svc.lgerobot.com/robot/b2b',
        'x-api-key': '29286223492d4ddd823849ab2489b8d2',
        'x-user-no': '000073KR2310110475873',
        'x-biz-channel': 'CHN000073',
        'x-country-code': 'KR',
        'x-language-code': 'ko-KR',
        'x-user-role': 'USR07305324',
        'refresh-token':
          '6778854031828f52d0e263eed8cd616ac768014dc89fbcc9e3b757ff52e13a29913aab18a09fd4b795a06150fa34306a',
      },
      pudu_config: {
        host: 'string',
        port: 'number',
        device_id: 'string',
        group_id: 'string',
      },
      bear_config: {
        host: 'string',
        vhost: 'string',
        auth_id: 'string',
        auth_key_file_path: 'string',
        mq_data_expiration_time: 'number',
      },
    },
    null,
    2,
  )

  return (
    <div>
      <Title level={2}>미들웨어 Config 수정</Title>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValues}
      >
        {/* <Form.Item
          name="storeId"
          label="Store ID"
          rules={[{ required: true, message: 'Please input the store ID' }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          name="ec2Id"
          label="서버 ID"
          rules={[
            { required: true, message: 'Please input the EC2 ID' },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="ssid"
          label="미들웨어 ID"
          rules={[
            { required: true, message: 'Please input the Robot middleware ID' },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="config"
          label="Config"
          rules={[{ required: true, message: 'Please input the config' }]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          {/* <Button onClick={() => handleConfigClick(allConfig)}>ALL</Button> */}
          <Button onClick={() => handleConfigClick(lgConfig)}>LG</Button>
          {/* <Button onClick={() => handleConfigClick(puduConfig)}>PUDU</Button> */}
          {/* <Button onClick={() => handleConfigClick(bearConfig)}>BEAR</Button> */}
        </Form.Item>
        <Form.List name="robots">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'id']}
                    rules={[{ required: true, message: 'Missing robot ID' }]}
                  >
                    <Input placeholder="Robot ID" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'modelCode']}
                    rules={[{ required: true, message: 'Missing model code' }]}
                  >
                    <Input placeholder="Model Code" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'nickname']}
                    rules={[{ required: true, message: 'Missing nickname' }]}
                  >
                    <Input placeholder="Nickname" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              {/* <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Robot
                </Button>
              </Form.Item> */}
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          {/* <Button
            danger
            type="primary"
            onClick={showDeleteConfirm}
            loading={confirmLoading}
            style={{ marginLeft: '8px' }}
          >
            Delete
          </Button> */}
          <Button
            type="default"
            onClick={() => navigate(-1)}
            style={{ marginLeft: '8px' }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default MiddlewareEdit
