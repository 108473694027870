import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import {
  Button,
  Form,
  Input,
  InputNumber,
  ModalFuncProps,
  Select,
  Space,
  Typography,
  notification,
} from 'antd'
import { Option } from 'antd/es/mentions'
import confirm from 'antd/es/modal/confirm'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { API_URL } from '../config'

const { Title } = Typography
const { TextArea } = Input

const EC2Edit = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { ec2Id: ec2Id } = useParams()
  const [loading, setLoading] = useState(true)
  const [initialValues, setInitialValues] = useState({})
  const [confirmLoading, setConfirmLoading] = useState(false)

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this ec2?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okCancel: true,
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setConfirmLoading(true)
        handleDelete()
      },
    } as ModalFuncProps)
  }

  const handleDelete = async () => {
    setConfirmLoading(true)
    try {
      await axios.post(`${API_URL}/middlewareEC2/del`, {
        ec2Id: ec2Id,
      })
      notification.success({
        message: 'Success',
        description: 'EC2 deleted successfully',
      })
      navigate(-1)
    } catch (error) {
      console.error('Error deleting ec2:', error)
      notification.error({
        message: 'Error',
        description: `EC2 리소스 삭제에 실패했습니다`,
      })
    } finally {
      setConfirmLoading(false)
    }
  }

  useEffect(() => {
    const fetchEC2 = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/middlewareEC2/${ec2Id}`,
        )
        setInitialValues(response.data)
        form.setFieldsValue(response.data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching ec2 data:', error)
        setLoading(false)
      }
    }

    fetchEC2()
  }, [ec2Id, form])

  const onFinish = async (values: any) => {
    try {
      await axios.post(
        `${API_URL}/middlewareEC2/edit`,
        values,
      )
      notification.success({
        message: 'Success',
        description: 'EC2 updated successfully',
      })
      navigate('/ec2s')
    } catch (error) {
      console.error('Error updating ec2:', error)
      notification.error({
        message: 'Error',
        description: `EC2 업데이트에 실패했습니다`,
      })
    }
  }

  const handleConfigClick = (config: string) => {
    form.setFieldsValue({
      config: config,
    })
  }

  return (
    <div>
      <Title level={2}>서버 수정</Title>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValues}
      >
        <Form.Item
          name="ec2Id"
          label="서버 ID"
          rules={[{ required: true, message: 'Please input the EC2 ID' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item name="name" label="서버명" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="ipAddr"
          label="IP 주소"
          rules={[
            { required: true, message: 'Please input the IP address' },
            // { validator: ipAddressValidator }
          ]}
        >
          <Input placeholder="54.180.248.118" />
        </Form.Item>
        <Form.Item
          name="instanceType"
          label="인스턴스 타입"
          rules={[
            { required: true, message: 'Please select the instance type' },
          ]}
        >
          <Input placeholder="t3.small" />
        </Form.Item>
        <Form.Item
          name="maxRmCount"
          label="최대 미들웨어 갯수"
          rules={[{ required: true, message: 'Please input the max RM count' }]}
        >
          <InputNumber min={1} />
        </Form.Item>

        <Form.List name="robots">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'id']}
                    rules={[{ required: true, message: 'Missing robot ID' }]}
                  >
                    <Input placeholder="Robot ID" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'modelCode']}
                    rules={[{ required: true, message: 'Missing model code' }]}
                  >
                    <Input placeholder="Model Code" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'nickname']}
                    rules={[{ required: true, message: 'Missing nickname' }]}
                  >
                    <Input placeholder="Nickname" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button
            danger
            type="primary"
            onClick={showDeleteConfirm}
            loading={confirmLoading}
            style={{ marginLeft: '8px' }}
          >
            Delete
          </Button>
          <Button
            type="default"
            onClick={() => navigate(-1)}
            style={{ marginLeft: '8px' }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default EC2Edit
