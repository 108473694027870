import {
  Button,
  Form,
  Input,
  InputNumber,
  Typography,
  notification,
} from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../config'

const { Title } = Typography
const { TextArea } = Input

const StoreAdd = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [isIdTaken, setIsIdTaken] = useState(false)

  const onFinish = async (values: any) => {
    try {
      await axios.post(`${API_URL}/store/add`, values)
      notification.success({
        message: 'Success',
        description: 'Store added successfully',
      })
      navigate(-1)
    } catch (error) {
      console.error('Error adding store:', error)
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          notification.error({
            message: 'Error',
            description: `Store ID 가 중복입니다.`,
          })
        }
      } else {
        notification.error({
          message: 'Error',
          description: `매장 추가에 실패했습니다.`,
        })
      }
    }
  }

  const handleIdChange = async () => {
    const id = form.getFieldValue('id')
    if (id) {
      try {
        const response = await axios.get(`${API_URL}/store/${id}`)
        if (response.data) {
          setIsIdTaken(true)
          form.setFields([
            {
              name: 'id',
              errors: ['This Store ID is already taken'],
            },
          ])
        } else {
          setIsIdTaken(false)
          form.setFields([
            {
              name: 'id',
              errors: [],
            },
          ])
        }
      } catch (error) {
        setIsIdTaken(false)
        form.setFields([
          {
            name: 'id',
            errors: [],
          },
        ])
      }
    }
  }

  useEffect(() => {
    handleIdChange()
  }, [form.getFieldValue('id')])

  return (
    <div>
      <Title level={2}>매장 추가</Title>

      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        onValuesChange={(_, allValues) => {
          if (allValues.id !== undefined) {
            handleIdChange()
          }
        }}
      >
        <Form.Item
          name="id"
          label="매장 ID"
          rules={[
            { required: true, message: 'Please input the ID' },
            {
              validator: (_, value) =>
                isIdTaken
                  ? Promise.reject('This Store ID is already taken')
                  : Promise.resolve(),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="매장명"
          rules={[{ required: true, message: 'Please input the Store name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="status"
          label="status"
          initialValue="1"
          hidden
          rules={[
            { required: true, message: 'Please select the instance type' },
          ]}
        >
          <InputNumber min={1} max={9} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={isIdTaken}>
            Submit
          </Button>
          <Button
            type="default"
            onClick={() => navigate(-1)}
            style={{ marginLeft: '8px' }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default StoreAdd
