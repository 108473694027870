import { Button, Layout, Table, theme } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { API_URL } from '../config'
import { render } from '@testing-library/react'

const { Content } = Layout

export const loader = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/middlewareEC2/middleware/list`,
    )
    return response.data
  } catch (error) {
    console.error('Error fetching middleware data:', error)
    throw new Error('Failed to load data')
  }
}

const Middleware = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/middlewareEC2/middleware/list`,
        )
        setData(response.data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const transformStatus = (status: string) => {
    switch (status) {
      case 'Provisioning':
        return '생성중'
      case 'Setting':
        return '세팅중'
      case 'InitializeDone':
        return '생성완료'
      case 'Removing':
        return '삭제중'
      case 'Removed':
        return '삭제완료'
      default:
        return status
    }
  }

  const columns = [
    {
      title: 'No.',
      dataIndex: 'id',
      key: 'id',
      render: (text: string, record: any) => (
        <Link to={`/middlewares/${record.id}/edit`} style={{ color: 'blue' }}>
          {text}
        </Link>
      ),
    },
    {
      title: '서버 ID',
      dataIndex: 'ec2Id',
      key: 'ec2Id',
    },
    {
      title: '미들웨어 ID',
      dataIndex: 'ssid',
      key: 'ssid',
    },
    {
      title: '생성 상태',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => transformStatus(status),
    },
  ]

  return (
    <div>
      <Button
        type="primary"
        onClick={() => navigate('/middlewares/add')}
        style={{ marginBottom: 16 }}
      >
        미들웨어 추가
      </Button>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="id"
      />
    </div>
  )
}

export default Middleware
