import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import Root from './routes/root'
import ErrorPage from './error-page'
import EC2 from './routes/ec2'
import Middleware, { loader as middlewareLoader } from './routes/middleware'
import axios from 'axios'
import MiddlewareAdd from './routes/middleware-add'
import V2MiddlewareAdd from './routes/v2-middleware-add'
import MiddlewareEdit from './routes/middleware-config-edit'
import EC2Add from './routes/ec2-add'
import EC2Edit from './routes/ec2-edit'
import Store from './routes/store'
import StoreAdd from './routes/store-add'
import StoreRestart from './routes/store-restart'

// Define the loader function

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'ec2s',
        element: <EC2 />,
      },
      {
        path: 'ec2s/add',
        element: <EC2Add />,
      },
      {
        path: 'ec2s/:ec2Id/edit',
        element: <EC2Edit />,
      },
      {
        path: 'stores',
        element: <Store />,
      },
      {
        path: 'stores/add',
        element: <StoreAdd />,
      },
      {
        path: 'stores/restart',
        element: <StoreRestart />,
      },
      {
        path: 'middlewares',
        element: <Middleware />,
        loader: middlewareLoader,
      },
      {
        path: 'middlewares/add',
        element: <MiddlewareAdd />,
        loader: middlewareLoader,
      },
      {
        path: 'v2/middlewares/add',
        element: <V2MiddlewareAdd />,
        loader: middlewareLoader,
      },
      {
        path: 'middlewares/:id/edit',
        element: <MiddlewareEdit />,
        loader: middlewareLoader,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
