import { MinusCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Space,
  Typography,
  notification,
} from 'antd'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../config'

const { Title } = Typography
const { TextArea } = Input

const EC2Add = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const onFinish = async (values: any) => {
    try {
      await axios.post(`${API_URL}/middlewareEC2/add`, values)
      notification.success({
        message: 'Success',
        description: 'EC2 added successfully',
      })
      navigate(-1)
    } catch (error) {
      console.error('Error adding ec2:', error)
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          notification.error({
            message: 'Error',
            description: `EC2 ID 가 중복입니다.`,
          })
        }
      } else {
        notification.error({
          message: 'Error',
          description: `EC2 리소스 추가에 실패했습니다.`,
        })
      }
    }
  }

  const handleConfigClick = (config: string) => {
    form.setFieldsValue({
      config: config,
    })
  }

  const ipAddressValidator = (_: any, value: string) => {
    const ipRegex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    if (value && !ipRegex.test(value)) {
      return Promise.reject(new Error('Invalid IP address format'))
    }
    return Promise.resolve()
  }

  return (
    <div>
      <Title level={2}>서버 추가</Title>

      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="ec2Id"
          label="서버 ID"
          rules={[{ required: true, message: 'Please input the EC2 ID' }]}
        >
          <Input placeholder="i-0a3432336c63ca862" />
        </Form.Item>
        <Form.Item name="name" label="서버명" rules={[{ required: true }]}>
          <Input placeholder='devops/robot-ami-test3'/>
        </Form.Item>
        <Form.Item
          name="ipAddr"
          label="IP 주소"
          rules={[
            { required: true, message: 'Please input the IP address' },
            // { validator: ipAddressValidator }
          ]}
        >
          <Input placeholder="54.180.248.118" />
        </Form.Item>
        <Form.Item
          name="instanceType"
          label="인스턴스 타입"
          rules={[
            { required: true, message: 'Please select the instance type' },
          ]}
        >
          <Input placeholder="t3.small" />
        </Form.Item>
        <Form.Item
          name="maxRmCount"
          label="최대 미들웨어 갯수"
          rules={[{ required: true, message: 'Please input the max RM count' }]}
        >
          <InputNumber min={1} placeholder='5'/>
        </Form.Item>

        <Form.List name="robots">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'id']}
                    rules={[{ required: true, message: 'Missing robot ID' }]}
                  >
                    <Input placeholder="Robot ID" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'modelCode']}
                    rules={[{ required: true, message: 'Missing model code' }]}
                  >
                    <Input placeholder="Model Code" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'nickname']}
                    rules={[{ required: true, message: 'Missing nickname' }]}
                  >
                    <Input placeholder="Nickname" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button
            type="default"
            onClick={() => navigate(-1)}
            style={{ marginLeft: '8px' }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default EC2Add
