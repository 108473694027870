import {
  ApiOutlined,
  CloudOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons'
import { Button, Layout, Menu, theme } from 'antd'
import { Footer } from 'antd/es/layout/layout'
import { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { API_URL, IS_DEV } from '../config'
const { Header, Sider, Content } = Layout

const Root = () => {
  const [collapsed, setCollapsed] = useState(false)
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken()

  return (
    <Layout>
      <div className="demo-logo" />

      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
          <Menu.Item key="1" icon={<CloudOutlined />}>
            <Link to="ec2s">서버</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<HomeOutlined />}>
            <Link to="stores">매장</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<ApiOutlined />}>
            <Link to="middlewares">미들웨어</Link>
          </Menu.Item>
          {IS_DEV ? (
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                padding: '10px',
                color: 'rgba(255, 255, 255, 0.65)',
                fontSize: '12px',
                textAlign: 'right',
              }}
            >
              ENV: {process.env.NODE_ENV}
            </div>
          ) : (
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                padding: '10px',
                color: 'rgba(255, 255, 255, 0.65)',
                fontSize: '12px',
                textAlign: 'right',
              }}
            >
              VERSION: {process.env.REACT_APP_VERSION}
            </div>
          )}
        </Menu>
      </Sider>

      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 680,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div id="detail">
            <Outlet />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          t'order ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  )
}
export default Root
