import { Button, Layout, Table, theme } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { API_URL } from '../config'

const { Content } = Layout

export const loader = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/middlewareEC2/list`,
    )
    return response.data
  } catch (error) {
    console.error('Error fetching ec2 data:', error)
    throw new Error('Failed to load data')
  }
}

const EC2 = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/middlewareEC2/list`,
        )
        setData(response.data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const columns = [
    {
      title: '서버 ID',
      dataIndex: 'ec2Id',
      key: 'ec2Id',
      render: (text: string, record: any) => (
        <Link to={`/ec2s/${record.ec2Id}/edit`} style={{ color: 'blue' }}>
          {text}
        </Link>
      ),
    },
    {
      title: '서버명',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'IP 주소',
      dataIndex: 'ipAddr',
      key: 'ipAddr',
    },
    {
      title: '인스턴스 타입',
      dataIndex: 'instanceType',
      key: 'instanceType',
    },
    {
      title: '최대 미들웨어 갯수',
      dataIndex: 'maxRmCount',
      key: 'maxRmCount',
    },
    {
      title: '미들웨어 여유공간',
      dataIndex: 'freeSpace',
      key: 'freeSpace',
    },
  ]

  return (
    <div>
      <Button
        type="primary"
        onClick={() => navigate('/ec2s/add')}
        style={{ marginBottom: 16 }}
      >
        서버 추가
      </Button>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="id"
      />
    </div>
  )
}

export default EC2
