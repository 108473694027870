import { Button, Form, Input, Typography } from 'antd'
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../config'

const { Title } = Typography
const { TextArea } = Input

const StoreRestart = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [isIdTaken, setIsIdTaken] = useState(false)

  const onFinish = async (values: any) => {
    const { id, ip } = values
    const response = await axios.post(`${API_URL}/middlewareEC2/stores/${id}/restart`, { ip })
    //   if (response.data.data === 'ok') {
    //     notification.success({
    //       message: 'Success',
    //       description: response.data.message, // Displaying the message from the response
    //     })
    //   } else {
    //     throw new Error('Unexpected response')
    //   }
    // } catch (error) {
    //   console.error('Error restarting store:', error)
    //   if (axios.isAxiosError(error)) {
    //     notification.error({
    //       message: 'Error',
    //       description: error.response?.data?.message || '매장 재실행에 실패했습니다.',
    //     })
    //   } else {
    //     notification.error({
    //       message: 'Error',
    //       description: '매장 재실행에 실패했습니다.',
    //     })
    //   }
  }

  return (
    <div>
      <Title level={2}>에이전트 재실행</Title>

      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="id"
          label="매장 ID"
          rules={[
            { required: true, message: 'Please input the ID' },
            {
              validator: (_, value) =>
                isIdTaken
                  ? Promise.reject('This Store ID is already taken')
                  : Promise.resolve(),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="ip"
          label="IP"
          rules={[
            { required: true, message: 'Please input the EC2 instance ip' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={isIdTaken}>
            Submit
          </Button>
          <Button
            type="default"
            onClick={() => navigate(-1)}
            style={{ marginLeft: '8px' }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default StoreRestart
